import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";

import ListNews from "@components/news/desktop/list/list-news";
import Popular from "@components/news/desktop/popular/Popular";
import Header from "@components/header/desktop/Header";

import auth from "@shared/auth/auth";

const Profile = () => {
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
  }, []);

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row>
          <Col xs md={8}>
            {user ? <ListNews user={user} /> : "loading..."}
          </Col>
          <Col xs md={4}>
            <div className="tw-h-screen tw-sticky tw-top-16">
              <Popular />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
