import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-bootstrap-date-picker";

import TableListNews from "../table/TableListNews";
import styles from "./list-news.module.css";
import { api } from "@shared/api";
import { INews } from "@shared/interfaces";

const ListNews = (props: any) => {
  const [news, setNews] = useState<INews[]>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [isSearch, setIsSearch] = useState(true);

  useEffect(() => {
    api
      .get(`post?order_by=id&sort_by=DESC&page=${page}&limit=15`)
      .then((res) => {
        setNews((prev) => [...prev, ...res.data.data]);
      });
  }, [page]);

  const handleSearch = async () => {
    setIsSearch(false);
    try {
      const { data: postList } = await api.get(
        `post?title=${search}&date_start=${dateStart}&date_end=${dateEnd}&order_by=${
          dateStart || dateEnd ? "createdAt" : "id"
        }&sort_by=${dateStart ? "ASC" : "DESC"}`
      );
      setNews(postList.data);
    } catch (error) {}
    setIsSearch(true);
  };

  const handleChangeDateStart = (e: any) => {
    setDateStart(e.target.value);
  };

  const handleChangeDateEnd = (e: any) => {
    setDateEnd(e.target.value);
  };

  return (
    <div className={styles.wrapListNews}>
      {news ? (
        <div className="tw-w-full tw-flex tw-flex-col tw-justify-center">
          <div>
            <div className="tw-grid tw-grid-cols-12 tw-gap-2">
              <div className="tw-col-span-3">
                <input
                  type="date"
                  className="border tw-px-2 tw-py-1 tw-w-full"
                  value={dateStart}
                  onChange={handleChangeDateStart}
                />
              </div>
              <div className="tw-col-span-3">
                <input
                  type="date"
                  className="border tw-px-2 tw-py-1 tw-w-full"
                  value={dateEnd}
                  onChange={handleChangeDateEnd}
                />
              </div>
              <input
                type="text"
                className="border tw-px-2 tw-py-1 tw-col-span-4"
                placeholder="Judul berita..."
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
              />
              <button
                className="tw-col-span-2 tw-bg-primary tw-text-white tw-rounded"
                onClick={handleSearch}
                disabled={!isSearch}
              >
                Cari Berita
              </button>
            </div>
          </div>
          <TableListNews news={news} user={props.user} />
          <button
            onClick={() => setPage(page + 1)}
            className="tw-w-fit tw-py-2 tw-px-4 tw-rounded-md tw-mx-auto tw-mt-4 tw-bg-primaryColor1 tw-text-white"
            type="button"
          >
            Tampilkan Lebih Banyak
          </button>
        </div>
      ) : (
        <>
          <h1 className={styles.titleInfo}>
            Kamu belum menuliskan sesuatu nih.
          </h1>
          <p className={styles.subTitleInfo}>
            Yuk menulis, supaya halaman profile kamu lebih menarik lagi !
          </p>
          <Link to="/admin/create-article" className={styles.btnCreate}>
            Mulai Menulis
          </Link>
          <img
            src="https://www.idntimes.com/assets/img/empty/empty-profile.png"
            className={styles.imgInfo}
            alt="img"
          />
        </>
      )}
    </div>
  );
};

export default ListNews;
