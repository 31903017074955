import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { api } from "@shared/api";
import styles from "./Popular.module.css";

const Popular = () => {
  const [popNews, setPopNews] = useState(null);

  useEffect(() => {
    api
      .get(`post?order_by=hits&sort_by=desc&limit=10`)
      .then((res) => {
        setPopNews(res.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <h3 className={styles.sectionTitle}>
        <span>Berita Terpopuler</span>
      </h3>
      <ul className={styles.trendingList}>
        {popNews !== null
          ? popNews.map((news, i) => {
              return (
                <li key={i} className="trending-list__li">
                  <Link to={`/admin/news/${news.id}`}>{news.title}</Link>
                </li>
              );
            })
          : ""}
      </ul>
    </>
  );
};

export default Popular;
