import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import auth from "@shared/auth/auth";
import { api } from "@shared/api";
import Header from "@components/header/desktop/Header";
import EditAdsComponent from "@components/ads/edit/edit-ads";

const EditAds = ({ match }: any) => {
  const [user, setUser] = useState<any>();
  const [ads, setAds] = useState<any>();
  const [adsID, setAdsID] = useState<any>();

  useEffect(() => {
    window.document.title = "Detail Iklan | KoranKaltara.com";
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
  }, []);

  useEffect(() => {
    if (match.params.id) {
      setAdsID(match.params.id);
    }
  }, [match]);

  useEffect(() => {
    if (adsID) {
      api.get(`ads/${adsID}`).then((res) => setAds(res.data.data));
    }
  }, [adsID]);

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row>
          {ads && (
            <Col xs md={12}>
              <Link
                to={`/admin/ads/${ads.id}`}
                className={"btn btn-outline-secondary my-3"}
              >
                Kembali ke Detail Iklan
              </Link>
              <div className="table-responsive">
                <EditAdsComponent ads={ads} />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default EditAds;
