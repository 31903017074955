import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import imageCompression from "browser-image-compression";
import slugify from "voca/slugify";
// @ts-ignore
import Select from "react-select";
// @ts-ignore
import SweetAlert from "sweetalert2-react";
import { Editor } from "@tinymce/tinymce-react";
import { AiFillCheckCircle } from "react-icons/ai";
import isBase64 from "is-base64";

import styles from "./create.module.css";
import { api } from "@shared/api";
import Header from "@components/header/desktop/Header";
import auth from "@shared/auth/auth";
import { ICategory } from "@shared/functions";
import { ADMIN } from "@shared/constants";

interface ImageProps {
  id: number;
  title: string;
  image_sm: string;
  image_md: string;
  image_lg: string;
}

const CreateArticle = (props: any) => {
  const [user, setUser] = useState<any>();
  const [isTitleError, setIsTitleError] = useState(false);
  const [title, setTitle] = useState("");
  const [remainingTitle, setRemainingTitle] = useState(200);
  const [excerpt, setExcerpt] = useState("");
  const [remainingExcerpt, setRemainingExcerpt] = useState(500);
  const [mainImage, setMainImage] = useState("");
  const [title_image, setTitleImage] = useState("");
  const [content, setContent] = useState("");
  const [isContentError, setIsContentError] = useState(false);
  const [category, setCategory] = useState<any>();
  const [isCategoryError, setIsCategoryError] = useState(false);
  const [optionCategories, setOptionCategories] = useState<ICategory[]>([]);
  const [isSuccessSave, setIsSuccessSave] = useState(false);
  const [isActive, setIsActive] = useState(0);
  const [labelSubmit, setLabelSubmit] = useState("Simpan Artikel");
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState("");
  const [isOpenLibraryImage, setIsOpenLibraryImage] = useState(false);
  const [images, setImages] = useState<ImageProps[]>([]);
  const [selectedImage, setSelectedImage] = useState<number>();
  const [imageSm, setImageSm] = useState("");
  const [imageMd, setImageMd] = useState("");
  const [imageLg, setImageLg] = useState("");

  useEffect(() => {
    api
      .get(`category?is_select_option=true`)
      .then((res) => setOptionCategories(res.data.data));
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
  }, []);

  useEffect(() => {
    if (title.length <= 200) {
      setRemainingTitle(200 - title.length);
    }
    if (excerpt.length <= 200) {
      setRemainingExcerpt(200 - excerpt.length);
    }
  }, [title, excerpt]);

  const handleGetImg = async (e: any) => {
    const imageFile = e.target.files[0];
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      getBase64(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const getBase64 = (file: any) => {
    let reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setMainImage(reader.result);
    };
    reader.onerror = function (error: any) {
      console.log("Error: ", error);
    };
  };

  const handleSave = async () => {
    setIsLoading(true);
    setLabelSubmit("Loading...");
    setIsTitleError(false);
    setIsCategoryError(false);
    setIsContentError(false);
    if (!title) {
      setIsTitleError(true);
      setLabelSubmit("Simpan Artikel");
      return;
    }
    if (!category) {
      setIsCategoryError(true);
      setLabelSubmit("Simpan Artikel");
      return;
    }
    if (!content) {
      setIsContentError(true);
      setLabelSubmit("Simpan Artikel");
      return;
    }

    const imageBase64 =
      mainImage &&
      isBase64(mainImage, { allowMime: true }) &&
      mainImage.split(";base64,").pop();

    const data = {
      title,
      slug: slugify(title),
      excerpt,
      content,
      category_id: category.value,
      image: imageBase64,
      image_sm: imageSm,
      image_md: imageMd,
      image_lg: imageLg,
      title_image: title_image,
      status: isActive,
      author_id: user && user.data.id,
      tags,
    };
    try {
      await api.post("post", data);
      props.history.push("/admin/profile");
      setIsSuccessSave(true);
      setIsLoading(false);
    } catch (error) {
      setIsSuccessSave(false);
      setIsLoading(false);
    }
    setLabelSubmit("Simpan Artikel");
  };

  const handleActive = (e: any) => {
    setIsActive(parseInt(e.target.value));
  };

  const handleEditorChange = (content: any) => {
    setContent(content);
  };

  const handleCloseModal = () => setIsOpenLibraryImage(!isOpenLibraryImage);

  const loadImage = async (page: number) => {
    try {
      const { data: resImage } = await api.get(
        `post?limit=18&page=${page}&order_by=title&sort_by=asc&image_only=true`
      );
      setImages(resImage.data);
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row className={"justify-content-center"}>
          <Col xs md={10}>
            <div className={styles.wrapForm}>
              <div className={"mt-4"}>
                <Select
                  value={category}
                  placeholder="Pilih kategori berita"
                  onChange={(e: any) => {
                    setCategory(e);
                    setIsCategoryError(false);
                  }}
                  options={optionCategories}
                />
                {isCategoryError && (
                  <p className={styles.messageError}>
                    Kategori berita jangan dikosongkan!
                  </p>
                )}
              </div>
              <div className={"mt-4"}>
                <TextareaAutosize
                  className={"form-control " + styles.inputForm}
                  onKeyUp={(e: any) => {
                    setTitle(e.target.value);
                    if (e.target.value.length > 0) {
                      setIsTitleError(false);
                    } else {
                      setIsTitleError(true);
                    }
                  }}
                  placeholder={"Tulis judul kamu di sini"}
                  maxLength={200}
                />
                <span className={styles.inputInfo}>
                  {remainingTitle}/200 Remaining
                </span>
                {isTitleError && (
                  <p className={styles.messageError}>
                    Judul berita jangan dikosongkan!
                  </p>
                )}
              </div>
              <div className={"mt-4"}>
                <TextareaAutosize
                  className={"form-control " + styles.inputTeaser}
                  onKeyUp={(e: any) => setExcerpt(e.target.value)}
                  placeholder={"Tulis cuplikan kamu di sini"}
                  maxLength={500}
                />
                <span className={styles.inputInfo}>
                  {remainingExcerpt}/500 Remaining
                </span>
              </div>
              <div className="mt-4 tw-relative">
                {mainImage && (
                  <div className="tw-flex tw-flex-col">
                    <img
                      src={mainImage}
                      className="tw-w-48 tw-h-auto tw-mb-2 tw-rounded"
                      alt="img"
                    />
                    <button
                      className="tw-bg-danger tw-text-white tw-w-48 tw-mb-4 tw-text-sm tw-py-2 tw-rounded"
                      onClick={() => setMainImage("")}
                    >
                      Hapus Gambar
                    </button>
                  </div>
                )}
                <input
                  id="file"
                  type="file"
                  onChange={(e) => handleGetImg(e)}
                />
                <label htmlFor="file">Sisipkan Gambar</label>
                <button
                  className="tw-absolute tw-right-4 tw-top-4 tw-bg-primary tw-text-white tw-p-4 tw-rounded tw-text-sm"
                  onClick={() => {
                    setIsOpenLibraryImage(true);
                    loadImage(1);
                  }}
                >
                  Buka Library Gambar
                </button>
              </div>
              <div className={"mt-4"}>
                <TextareaAutosize
                  className={"form-control " + styles.inputTeaser}
                  onKeyUp={(e: any) => setTitleImage(e.target.value)}
                  placeholder={"Tulis keterangan foto di sini"}
                  maxLength={150}
                />
              </div>
              <div className={"mt-4"}>
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                  value={content}
                  init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar: `formatselect fontsizeselect forecolor blockquote | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat`,
                    mobile: { theme: "mobile" },
                  }}
                  onEditorChange={(e) => handleEditorChange(e)}
                />
                {isContentError && (
                  <p className={styles.messageError}>
                    Isi berita jangan dikosongkan!
                  </p>
                )}
              </div>

              <div className={"mt-4"}>
                <TextareaAutosize
                  className={"form-control " + styles.inputTeaser}
                  onKeyUp={(e: any) => setTags(e.target.value)}
                  placeholder={"Tulis Tag berita di sini, pisahkan dengan koma"}
                  maxLength={150}
                />
              </div>

              {user && user.data.role === ADMIN && (
                <div className={"mt-4"}>
                  <p>
                    <input
                      type="radio"
                      id="is_active"
                      value={1}
                      checked={isActive === 1}
                      onChange={(e) => {
                        handleActive(e);
                      }}
                    />
                    <label htmlFor="is_active">Tayang</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      id="is_active_n"
                      value={0}
                      checked={isActive === 0}
                      onChange={(e) => {
                        handleActive(e);
                      }}
                    />
                    <label htmlFor="is_active_n">Tidak Tayang</label>
                  </p>
                </div>
              )}

              <div className={"mt-4"}>
                <button
                  type="button"
                  className={"btn btn-primary " + styles.btnSave}
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {labelSubmit}
                </button>
                <SweetAlert
                  show={isSuccessSave}
                  title="Sukses"
                  text="Artikel berhasil dibuat."
                  onConfirm={() => {
                    setIsSuccessSave(false);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal
        show={isOpenLibraryImage}
        onHide={handleCloseModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Pustaka Gambar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tw-grid tw-grid-cols-6 tw-gap-2">
            {images &&
              images.length > 0 &&
              images.map(
                (image) =>
                  image.image_md && (
                    <div
                      key={`image-${image.id}`}
                      className={`tw-col-span-1 border p-1 tw-rounded tw-cursor-pointer tw-relative tw-transition-all tw-duration-300 hover:tw-border-gray-600 ${
                        selectedImage === image.id ? "tw-border-primary" : ""
                      }`}
                      onClick={() => setSelectedImage(image.id)}
                    >
                      <div className="tw-h-16 tw-w-full tw-overflow-hidden">
                        <img
                          src={image.image_md}
                          alt={image.title}
                          className="tw-w-full tw-h-full tw-object-cover"
                        />
                      </div>
                      {selectedImage === image.id && (
                        <AiFillCheckCircle className="tw-absolute tw-right-2 tw-top-2 tw-text-primary border tw-border-white tw-rounded-full tw-bg-white tw-w-8 tw-h-8" />
                      )}
                      <h3 className="my-2 tw-text-[10px]">{image.title}</h3>
                    </div>
                  )
              )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="border py-1 px-3 tw-rounded tw-bg-primaryColor1 tw-text-white disabled:tw-bg-primaryColor3"
            onClick={() => {
              const image =
                selectedImage &&
                images.find((item) => item.id === selectedImage);
              if (image) {
                setMainImage(image.image_md);
                setImageLg(image.image_lg);
                setImageMd(image.image_md);
                setImageSm(image.image_sm);
              }
              handleCloseModal();
            }}
            disabled={!selectedImage}
          >
            Pilih
          </button>
          <button
            type="button"
            className="border py-1 px-3 tw-rounded tw-bg-danger tw-text-white"
            onClick={() => {
              handleCloseModal();
              setSelectedImage(0);
            }}
          >
            Batal
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateArticle;
