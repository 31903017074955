import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import TextareaAutosize from "react-textarea-autosize";
import { useHistory } from "react-router-dom";

// @ts-ignore
import Select from "react-select";
// @ts-ignore
import DatePicker from "react-datepicker";
// @ts-ignore
import SweetAlert from "sweetalert2-react";
// @ts-ignore
import { parseISO } from "date-fns/esm";

import styles from "./edit.module.css";

import "react-datepicker/dist/react-datepicker.css";
import { api } from "@shared/api";
import auth from "@shared/auth/auth";
import { numeric } from "@shared/functions";
import { ADS_TYPE_OPTIONS, optionCompressImage } from "@shared/constants";
import { Ads, STATUS_ACTIVE, STATUS_INACTIVE } from "@shared/interfaces";

const EditAdsForm = (props: any) => {
  const [user, setUser] = useState<any>();
  const [ads, setAds] = useState<Ads>();
  const [adsID, setAdsID] = useState<any>();
  const [optionAdsType, setOptionAdsType] = useState<any>();
  const [adsType, setAdsType] = useState<any>();
  const [adsTypeID, setAdsTypeID] = useState<any>();
  const [isAdsTypeError, setIsAdsTypeError] = useState(false);
  const [title, setTitle] = useState<any>();
  const [isTitleError, setIsTitleError] = useState(false);
  const [url, setUrl] = useState<any>();
  const [price, setPrice] = useState<any>();
  const [isActive, setIsActive] = useState("0");
  const [marketing, setMarketing] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [img, setImg] = useState<any>();
  const [reviewImg, setReviewImg] = useState<any>();
  const [isSuccessSave, setIsSuccessSave] = useState(false);
  const [labelSubmit, setLabelSubmit] = useState("Simpan Iklan");
  const [optionAdvList, setOptionAdvList] = useState<any>([
    { label: "Pilih kanal Advertorial", value: "" },
  ]);
  const [advList, setAdvList] = useState<any>();
  const [status, setStatus] = useState<any>("");

  const history = useHistory();

  useEffect(() => {
    // api
    //   .get(`admin/ads-type/for-form`)
    //   .then((res) => setOptionAdsType(res.data.data));
    // api.get(`admin/adv-list`).then((res) => {
    //   const temp = Array.from(new Set([...optionAdvList, ...res.data.data]));
    //   setOptionAdvList(temp);
    // });
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props !== undefined) {
      setAds(props.ads);
      setAdsID(props.adsID);
    }
  }, [props]);

  useEffect(() => {
    if (ads) {
      setTitle(ads.title || "");
      setAdsType(ads.ads_type || "");
      setUrl(ads.link || "");
      setPrice(ads.price || 0);
      setStartDate(ads?.date_start ? parseISO(ads.date_start) : "");
      setEndDate(ads.date_end ? parseISO(ads.date_end) : "");
      setIsActive(ads.status === STATUS_ACTIVE ? "1" : "0");
      setReviewImg(ads.image || "");
      setStatus(ads.status || "");
    }
  }, [ads]);

  useEffect(() => {
    if (title === null) {
      setIsTitleError(false);
    } else if (title?.length === 0) {
      setIsTitleError(true);
    } else {
      setIsTitleError(false);
    }
  }, [title]);

  useEffect(() => {
    if (adsType !== null) {
      setAdsTypeID(adsType?.id || 0);
    }
  }, [adsType]);

  const handleGetImg = async (e: any) => {
    const imageFile = e.target.files[0];
    try {
      const compressedFile = await imageCompression(
        imageFile,
        optionCompressImage
      );
      const imgBase64 = getBase64(compressedFile);
      setImg(imgBase64);
      setReviewImg(imgBase64);
    } catch (error) {
      console.log(error);
    }
  };

  const getBase64 = (file: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg(reader.result);
      setReviewImg(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const getValueAdv = () =>
    optionAdvList?.find((el: any) => el?.value === ads?.ads_type);

  const handleSave = () => {
    setLabelSubmit("Loading...");
    setIsTitleError(false);
    if (!title) {
      setIsTitleError(true);
      setLabelSubmit("Update Iklan");
      return;
    }

    const payload = {
      ads_type: adsType,
      category_id: advList ? advList.value : getValueAdv()?.value,
      title: title,
      link: url,
      price: price ?? 0,
      date_start: startDate,
      date_end: endDate,
      is_active: isActive,
      marketing: marketing,
      image: img,
      status,
    };

    api
      .patch(`ads/${adsID}`, payload, {
        headers: { Authorization: `Bearer ${user.access_token}` },
      })
      .then(() => {
        setIsSuccessSave(true);
        history.goBack();
      })
      .catch(() => {
        setIsSuccessSave(false);
      });
    setLabelSubmit("Update Iklan");
  };

  return (
    <>
      <div className={styles.wrapForm}>
        <div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Jenis Iklan
            </label>
            <div className="col-sm-9">
              <Select
                value={ADS_TYPE_OPTIONS?.find((el) => el?.value === adsType)}
                placeholder="Pilih jenis iklan"
                onChange={(e: any) => {
                  setAdsType(e?.value);
                  setIsAdsTypeError(false);
                }}
                options={ADS_TYPE_OPTIONS}
              />
              {isAdsTypeError && (
                <p className={styles.messageError}>
                  Kategori berita jangan dikosongkan!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Title Iklan
            </label>
            <div className="col-sm-9">
              <TextareaAutosize
                defaultValue={title}
                className={"form-control " + styles.inputForm2}
                onKeyUp={(e: any) => {
                  const { value } = e.target;
                  setTitle(value);
                  if (value?.length > 0) {
                    setIsTitleError(false);
                  } else {
                    setIsTitleError(true);
                  }
                }}
                placeholder={"Tulis title iklan disini"}
                maxLength={255}
              />
              {isTitleError && (
                <p className={styles.messageError}>
                  Title iklan jangan dikosongkan!
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              URL Iklan
            </label>
            <div className="col-sm-9">
              <TextareaAutosize
                className={"form-control " + styles.inputForm2}
                onKeyUp={(e: any) => {
                  setUrl(e.target.value);
                }}
                placeholder={
                  "URL iklan disini, lengkap dengan http atau https. Misal: https://kukarkab.co.id"
                }
                maxLength={255}
                defaultValue={ads?.link}
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Harga Iklan
            </label>
            <div className="col-sm-9">
              <TextareaAutosize
                defaultValue={price}
                className={"form-control " + styles.inputForm2}
                onKeyUp={(e: any) => {
                  setPrice(parseInt(e.target.value));
                }}
                placeholder={
                  "Harga iklan tulis disini (memudahkan proses rekapitulasi)"
                }
                maxLength={255}
                onKeyPress={(e: any) => {
                  numeric(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Tanggal Mulai
            </label>
            <div className="col-sm-9">
              <DatePicker
                className={"form-control " + styles.inputForm2}
                selected={startDate}
                value={startDate}
                onChange={(e: any) => {
                  setStartDate(e);
                }}
                type="text"
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Tanggal Selesai
            </label>
            <div className="col-sm-9">
              <DatePicker
                className={"form-control " + styles.inputForm2}
                selected={endDate}
                value={endDate}
                onChange={(e: any) => setEndDate(e)}
                type="text"
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
        </div>
        {/* <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">
              Nama Marketing
            </label>
            <div className="col-sm-9">
              <TextareaAutosize
                value={marketing}
                className={"form-control " + styles.inputForm2}
                onKeyUp={(e: any) => {
                  setMarketing(e.target.value);
                }}
                placeholder={"Tuliskan nama marketing"}
                maxLength={255}
              />
            </div>
          </div>
        </div> */}
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right">Gambar</label>
            <div className="col-sm-9">
              {reviewImg && (
                <div style={{ position: "relative" }}>
                  <img src={reviewImg} className={styles.reviewImg} alt="img" />
                  <button
                    className={"btn btn-danger " + styles.btnDeleteImg}
                    onClick={() => {
                      setImg("");
                      setReviewImg("");
                    }}
                  >
                    Hapus Gambar
                  </button>
                </div>
              )}
              <input id="file" type="file" onChange={(e) => handleGetImg(e)} />
              <label htmlFor="file">Sisipkan Gambar</label>
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3  col-form-label text-right">
              Status
            </label>
            <div className="col-sm-9">
              <span className={"mx-3"}>
                <input
                  className={styles.radio}
                  type="radio"
                  id="status"
                  value={1}
                  checked={status === STATUS_ACTIVE}
                  onChange={() => {
                    setStatus(STATUS_ACTIVE);
                  }}
                />
                <label htmlFor="status">Aktif</label>
              </span>
              <span className={"mx-3"}>
                <input
                  className={styles.radio}
                  type="radio"
                  id="status_n"
                  value={0}
                  checked={status === STATUS_INACTIVE}
                  onChange={() => {
                    setStatus(STATUS_INACTIVE);
                  }}
                />
                <label htmlFor="status_n">Tidak Aktif</label>
              </span>
            </div>
          </div>
        </div>
        <div className={"mt-2"}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-right"></label>
            <div className="col-sm-9">
              <button
                type="button"
                className={`btn ${styles.btnCancel}`}
                onClick={() => history.goBack()}
              >
                Back
              </button>
              <button
                type="button"
                className={"btn btn-primary " + styles.btnSave}
                onClick={handleSave}
              >
                {labelSubmit}
              </button>
              <SweetAlert
                show={isSuccessSave}
                title="Sukses"
                text="Iklan berhasil diupdate"
                onConfirm={() => {
                  setIsSuccessSave(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdsForm;
