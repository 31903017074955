import React, { useState, useEffect } from "react";

import Login from "@pages/login/Login";
import Profile from "@pages/profile/profile";
import auth from "@shared/auth/auth";

const Home = () => {
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
  }, []);

  return <>{user ? <Profile /> : <Login />}</>;
};

export default Home;
