import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row, Form } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
// @ts-ignore
import Select from "react-select";

import Header from "@components/header/desktop/Header";
import { api } from "@shared/api";
import auth from "@shared/auth/auth";
import { TableListAds } from "@components/ads/tables";
import { Ads } from "@shared/interfaces";
import { ADS_TYPE_OPTIONS } from "@shared/constants";
import { assemblyParameters } from "@shared/functions";

const AdsPage = () => {
  const [user, setUser] = useState<any>();
  const [ads, setAds] = useState<Ads[]>();
  const [formSearch, setFormSearch] = useState<any>();

  useEffect(() => {
    window.document.title = "Iklan | KoranKaltara.com";
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
    api.get(`ads`).then((res) => setAds(res.data.data));
  }, []);

  const handleFilter = () => {
    api
      .get(`ads?${assemblyParameters(formSearch)}`)
      .then((res) => setAds(res.data.data));
  };

  return (
    <>
      <Header user={user} />
      <Container className="tw-mt-20 pt-2">
        <Row>
          <Col xs md={12}>
            <Link to="/admin/ads/create" className={"linkEdit tw-mb-4"}>
              Tambah Iklan
            </Link>

            <div className="wrap mb-2 tw-mt-4">
              <div className="form-group row">
                <Col sm={5}>
                  <Select
                    value={ADS_TYPE_OPTIONS?.find(
                      (el) => el.value === formSearch?.adsType
                    )}
                    placeholder="Pilih jenis iklan"
                    onChange={(e: any) =>
                      setFormSearch({ ...formSearch, ads_type: e?.value })
                    }
                    options={ADS_TYPE_OPTIONS}
                  />
                </Col>
                <Col sm={5}>
                  <TextareaAutosize
                    className={"form-control"}
                    onKeyUp={(e: any) =>
                      setFormSearch({ ...formSearch, title: e.target.value })
                    }
                    placeholder={"Cari iklan"}
                    maxLength={255}
                  />
                </Col>
                <Col sm={1} style={{ display: "flex", alignItems: "center" }}>
                  <div key={`active`}>
                    <Form.Check
                      style={{ margin: 0 }}
                      type={"checkbox"}
                      id={`active`}
                      label={`Aktif`}
                      onClick={(e: any) =>
                        setFormSearch({
                          ...formSearch,
                          is_active: e.target.checked ? "1" : "0",
                        })
                      }
                    />
                  </div>
                </Col>
                <Col sm={1}>
                  <button className="linkDetail" onClick={handleFilter}>
                    Filter
                  </button>
                </Col>
              </div>
            </div>

            <TableListAds ads={ads} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdsPage;
