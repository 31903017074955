import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { api } from "@shared/api";
import Detail from "@components/ads/card/Detail";
import auth from "@shared/auth/auth";
import Header from "@components/header/desktop/Header";

const ShowAds = ({ match }: any) => {
  const [user, setUser] = useState<any>();
  const [ads, setAds] = useState<any>();
  const [adsID, setAdsID] = useState<any>();

  useEffect(() => {
    window.document.title = "Detail Iklan | KoranKaltara.com";
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
  }, []);

  useEffect(() => {
    if (match.params.id) {
      setAdsID(match.params.id);
    }
  }, [match]);

  useEffect(() => {
    if (adsID) {
      api.get(`ads/${adsID}`).then((res) => setAds(res.data.data));
    }
  }, [adsID]);

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row>
          <Col xs md={12}>
            <Link to="/admin/ads" className={"btn btn-outline-secondary my-3"}>
              Kembali ke Iklan
            </Link>
            <div className="table-responsive">
              <Detail ads={ads} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ShowAds;
