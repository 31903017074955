import React, { FC } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import styles from "../ads.module.css";
import { Ads, STATUS_ACTIVE } from "@shared/interfaces";

interface TableListAdsProps {
  ads: Ads[] | undefined;
}

export const TableListAds: FC<TableListAdsProps> = ({ ads }) => {
  return (
    <div className="table-responsive">
      <table className={`table table-striped ${styles.table}`}>
        <thead className="thead-light">
          <tr>
            <th>Title</th>
            <th>Adv</th>
            <th>URL</th>
            <th>Mulai</th>
            <th>Selesai</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ads && ads.length > 0 ? (
            ads?.map((data_ads, i: number) => (
              <tr key={i}>
                <td style={{ width: "25%" }}>{data_ads.title}</td>
                <td>{data_ads?.ads_type}</td>
                <td>{data_ads.link}</td>
                <td className="text-center">{data_ads.date_start}</td>
                <td className="text-center">{data_ads.date_end}</td>
                <td style={{ width: "100px" }} className="text-center">
                  {data_ads.status === STATUS_ACTIVE ? (
                    <Badge bg="success">Aktif</Badge>
                  ) : (
                    <Badge bg="danger">Tidak Aktif</Badge>
                  )}
                </td>
                <td style={{ width: "100px" }} className="text-center">
                  <Link
                    to={`/admin/ads/edit/${data_ads.id}`}
                    className={styles.linkTable}
                  >
                    Edit
                  </Link>
                  <Link
                    to={`/admin/ads/show/${data_ads.id}`}
                    className={styles.linkTable}
                  >
                    Detail
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>Belum ada data iklan yang bisa ditampilkan.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
