import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Header from "@components/header/desktop/Header";
import auth from "@shared/auth/auth";
import CreateAdsComponent from "@components/ads/create/create-ads";

const CreateAds = () => {
  const [user, setUser] = useState<any>();

  useEffect(() => {
    window.document.title = "Buat Iklan | KoranKaltara.com";
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
  }, []);

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row>
          <Col xs={12} md={12}>
            <div className="table-responsive">
              <CreateAdsComponent />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateAds;
