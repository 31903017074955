import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
// @ts-ignore
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";

import styles from "./show.module.css";
import Header from "@components/header/desktop/Header";
import Popular from "@components/news/desktop/popular/Popular";
import auth from "@shared/auth/auth";
import { api } from "@shared/api";
import { IPost } from "@shared/functions";

const ShowArticle = ({ match }: any) => {
  const [user, setUser] = useState<any>();
  const [news, setNews] = useState<IPost>();
  const [newsID, setNewsID] = useState<any>();

  useEffect(() => {
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
    setNewsID(match.params.id);
  }, [match]);

  useEffect(() => {
    if (user !== null) {
      api.get(`/post/${newsID}`).then((res: any) => setNews(res.data.data));
    }
  }, [user, newsID]);

  return (
    <>
      <Header user={user} />
      <Container className="mt-5 pt-3">
        <Row>
          <Col xs md={8}>
            {news && (
              <div className={styles.my20}>
                <button className="linkEdit me-2">
                  <Link to="/admin/profile" className="text-white">
                    Kembali
                  </Link>
                </button>
                <button className="linkDetail">
                  <Link
                    to={`/admin/news/edit/${news.id}`}
                    className="text-white"
                  >
                    Ubah
                  </Link>
                </button>
                <h1 className={styles.title + " mt-3"}>{news.title}</h1>
                {news.image_lg && (
                  <img
                    src={news.image_lg}
                    alt="img"
                    className={styles.imgNews}
                  />
                )}
                {news.title_image && (
                  <div className={styles.imgTitle}>{news.title_image}</div>
                )}
                {news.excerpt && (
                  <div className={styles.teaser}>{news.excerpt}</div>
                )}
                {news.content && (
                  <div className={"mt-2"}>{renderHTML(news.content)}</div>
                )}
              </div>
            )}
          </Col>
          <Col xs md={4}>
            <div className="sticky-top">
              <Popular />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ShowArticle;
