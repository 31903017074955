import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import auth from "@shared/auth/auth";
import { api } from "@shared/api";
import EditAdsForm from "./form/edit.form";

const EditAdsComponent = (props: any) => {
  const [user, setUser] = useState(null);
  // const [adsID, setAdsID] = useState(null);
  // const [ads, setAds] = useState(null);
  const params: any = useParams();
  const id = params?.id;

  useEffect(() => {
    window.document.title = "Update Iklan | KoranKaltara.com";
    const authUser = auth.user() || "";
    if (authUser) setUser(JSON.parse(authUser));
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     api
  //       .get(`${process.env.REACT_APP_BASEURL}/admin/ads/${id}`)
  //       .then((res) => setAds(res.data.data));
  //   }
  // }, [user, id]);

  return <EditAdsForm ads={props?.ads} adsID={id} />;
};

export default EditAdsComponent;
