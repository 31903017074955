import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";

export const numeric = (e: any) => {
  let ch = String.fromCharCode(e.which);
  if (!/[0-9]/.test(ch)) {
    e.preventDefault();
  }
};

export const getBase64 = (file: any) => {
  let res;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => (res = reader.result);
  reader.onerror = (error) => {
    console.log("Error: ", error);
  };
  return res;
};

export const removeEmpty = (obj: { [x: string]: any }) => {
  const finalObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      const nestedObj = removeEmpty(obj[key]);
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj;
      }
    } else if (Array.isArray(obj[key])) {
      if (obj[key].length) {
        obj[key].forEach((x: { [x: string]: any }) => {
          const nestedObj = removeEmpty(x);
          if (Object.keys(nestedObj).length) {
            finalObj[key] = finalObj[key]
              ? [...finalObj[key], nestedObj]
              : [nestedObj];
          }
        });
      }
    } else if (obj[key] !== "" && obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key];
    }
  });
  return finalObj;
};

export const isBase64 = (str: string) => {
  const notBase64 = /[^A-Z0-9+\/=]/i;
  const len = str.length;
  if (!len || len % 4 !== 0 || notBase64.test(str)) {
    return false;
  }
  const firstPaddingChar = str.indexOf("=");
  return (
    firstPaddingChar === -1 ||
    firstPaddingChar === len - 1 ||
    (firstPaddingChar === len - 2 && str[len - 1] === "=")
  );
};

export const assemblyParameters = (params: any) => {
  if (isUndefined(params) || isEmpty(params)) return null;
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};
