import { ADMIN } from "@shared/constants";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import "./style.css";

const TableListNews = (props) => {
  const [dataNews, setDataNews] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (props !== undefined) {
      setDataNews(props.news);
      setUser(props.user);
    }
  }, [props]);

  const columns = [
    {
      name: "Judul",
      selector: "title",
      sortable: true,
      maxWidth: "50%",
    },
    {
      name: "Kategori",
      cell: (row) => row.category?.name || "-",
      sortable: true,
      width: "120px",
    },
    {
      name: "Terbit",
      cell: (row) => (
        <>
          {row.status === 1 ? (
            <span className="badge badge-primary">tampil</span>
          ) : (
            <span className="badge badge-secondary">tidak</span>
          )}
        </>
      ),
      width: "90px",
    },
    {
      name: "Gambar",
      cell: (row) => (
        <div>
          {row.image_sm ? (
            <img src={row.image_sm} alt="img" />
          ) : (
            <span className="badge badge-secondary">no img</span>
          )}
        </div>
      ),
      width: "90px",
    },
    {
      name: "Aksi",
      right: true,
      textAlign: "center",
      cell: (row) => (
        <>
          {(+row.author.id === +user.data.id || user.data.role === ADMIN) && (
            <Link className={"linkEdit"} to={`/admin/news/edit/${row.id}`}>
              Edit
            </Link>
          )}
          <Link className={"linkDetail"} to={`/admin/news/${row.id}`}>
            View
          </Link>
        </>
      ),
      width: "150px",
    },
  ];

  return (
    <div>
      <DataTable
        noHeader={true}
        columns={columns}
        data={dataNews}
        theme="solarized"
        pagination={false}
        noDataComponent={
          <h3 className="tw-mt-4 tw-font-normal tw-text-base">
            Tidak ada berita yang bisa ditampilkan!
          </h3>
        }
      />
    </div>
  );
};

export default TableListNews;
